import React from "react"

const NotFound = () => {
  function redirect() {
    typeof window !== "undefined" &&
      (window.location = "https://www.goodingco.com/")
  }

  return <>{redirect()}</>
}

export default NotFound
